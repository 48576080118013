<template>
	<div class="inner">
		<img src="../assets/news-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="tc ft24 l16 mb40 pt30 mt50">{{newsInfo.title}}</div>
			<div class="bl9 ft18 el-border-bottom over pb30 mb40">
				<span class="ml20 fr">所属：{{newsInfo.category == 1 ? '企业新闻' : newsInfo.category == 2 ? '行业动态' : '企业公告'}}</span>
				<!--<span class="ml20 fr">浏览量：3500</span>-->
				<span class="ml20 fr">{{newsInfo.updateTime}}</span>
			</div>
			<div class="content tj bl6 ft20 pb30 mb30 el-border-bottom pb24">
				<div class="l18 tj pb30" v-html="newsInfo.content">
				</div>
				<!--<div class="l18 tj pb30"> 我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清,-->
					<!--我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清,-->
					<!--我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清，我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清。-->
				<!--</div>-->
				<!--<div class="l18 tj pb30"> 我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清,-->
					<!--我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清,-->
					<!--我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清，我司荣获2017年度中国（天津）自贸区平行进口汽车清,我司荣获2017年度中国（天津）自贸区平行进口汽车清。-->
				<!--</div>-->
				<!--<img src="../assets/about-detals-bg.png" width="100%" />-->
			</div>
			<div class="over mt20">
				<div class="fl">
					<div class="ft20 bl6 line1 hover tr3" @click="handlePrev(newsInfo.preId)">上一篇：{{newsInfo.preTitle ? newsInfo.preTitle : '没有了'}}</div>
					<div class="pt16 ft20 line1 hover tr3 bl6" @click="handleNext(newsInfo.nextId)">下一篇：{{newsInfo.nextTitle ? newsInfo.nextTitle : '没有了'}}</div>
				</div>
				<!--<div class="over fr over pb40 tr3">-->
					<!--<div class="ft20 bl6 fl pt16">分享到：</div>-->
					<!--<img src="../assets/wechat-img.png" width="55" class="ml16 hover" />-->
					<!--<img src="../assets/webo-img.png" width="55" class="ml16 hover" />-->
					<!--<img src="../assets/qqz-img.png" width="55" class="ml16 hover" />-->
					<!--<img src="../assets/douban-img.png" width="55" class="ml16 hover" />-->
					<!--<img src="../assets/tieba-img.png" width="55" class="ml16 hover" />-->
				<!--</div>-->
			</div>
			<div class="over pt30 pb24 mt50 el-border-bottom">
				<span class="ft24 fl">推荐阅读</span>
				<span class="ft18 bl6 flex fr hover" @click="$router.push('/news')">
					查看更多
					<i class="el-icon-arrow-right"></i>
				</span>
			</div>
			<div class="pt28 ft20 news-list-o hover tr3 flex-between line1 bl6" v-for="(item,index) in newsList" :key="index" @click="handleDetails(item.id)">
				<div class="line1 pr20 fl">
					{{item.title}}
				</div>
				<div class="ft20 fr bl9">{{item.updateTime}}</div>
			</div>
			<div style="height: 200px;"></div>
		</div>
	</div>
</template>

<script>
	import { newsDetails, getNews} from '@/api/index.js'
	export default {
		data() {
			return {
				newsInfo:{
					id: '',
					title: '', // 新闻标题
					mainPic: '', // 新闻主图
					source: '', // 新闻来源
					category: '', // 新闻类别
					shortDesc: '', // 新闻摘要
					content:'', // 新闻内容
					updateTime: '', // 发布时间
					preId: '', // 上一篇新闻id
					preTitle: '',  // 上一篇新闻标题
					nextId: '', // 下一篇新闻id
					nextTitle: '' // 下一篇新闻标题
				},
				total: 0,
				newsList: [],
				queryParams: {
					category: '',
					pageNum: 1,
					pageSize: 4
				},
			}
		},
		created(){
			this.getNewsDetail(this.$route.query.id)
			this.getNewsInfo()
		},
		methods: {
			getNewsDetail(id){
				newsDetails(id).then(res => {
					if(res.code === 200){
						this.newsInfo = res.data;
					}
				})
			},
			getNewsInfo(){
				getNews(this.queryParams).then(res => {
					if(res.code === 200){
						this.newsList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			handlePrev(id){
				if(id){
					this.getNewsDetail(id);
					this.$router.push({
						path: '/news-details',
						query:{
							id: id
						}
					})
				}
			},
			handleNext(id){
				if(id){
					this.getNewsDetail(id);
					this.$router.push({
						path: '/news-details',
						query:{
							id: id
						}
					})
				}
			},
			handleDetails(id){
				this.getNewsDetail(id);
				this.$router.push({
					path: '/news-details',
					query:{
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.news-list-o:hover{
		color: #B4232F;
		transform: translateX(4px);
	}
</style>
